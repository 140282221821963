.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .ellipse {
    position: absolute;
    right: 0;
    top: -40px;
    width: 500px;
    height: 500px;
    z-index: -1000;

    @media #{$md-layout} {
      right: -70px;
    }
  }

  .blueEllipse {
    position: absolute;
    width: min(1400px, 100vw);
    height: min(1400px, 100vw);
    left: 0px;
    bottom: -600px;
    z-index: -1000;

    @media #{$lg-layout} {
      bottom: -300px; // Adjust for responsiveness
    }

    @media #{$md-layout} {
      bottom: -250px;
    }

    @media #{$large-mobile} {
      bottom: -150px;
    }
  }

  .p-absolute {
    position: relative;
    padding-top: 20px;

    .invesment-text {
      position: absolute;
      bottom: 0;
      font-size: max(45px, min(144px, 9vw));
      font-weight: 800;
      color: transparent;
      -webkit-text-stroke: 2px $secondary-main;
      text-stroke: 2px $secondary-main;
      opacity: 10%;
      text-transform: uppercase;
    }

    .ring-now {
      z-index: 1;
      border-radius: 60px;
      background-color: $secondary-main;
      color: white;
      padding: min(10px, 2%) min(40px, 8%);
      margin: 0px auto;
      font-size: min(3.5vw, 45px);

      @media screen and (max-width: 767px) {
        padding: min(10px, 20px) min(40px, 20px); // Adjust as needed for smaller screens
      }
    }
  }

  .pricing-billing-duration {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 95px;
    background-color: $secondary-main;

    ul {
      list-style: none;
      padding: 5px;
      //   margin-bottom: 60px;
      border-bottom: none;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      border-radius: 110px;
      gap: 10px;

      .nav-item {
        margin-right: 10px;
        // &:last-child {
        //     margin-right: 0;
        // }
      }

      .nav-link {
        color: $white;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.025em;
        font-family: var(--font-primary);
        background-color: transparent;
        border: 1px solid $white;
        border-radius: 60px;
        padding: 14px 30px;

        &.active {
          background-color: $primary-main;
          color: $white;
          border: 1px solid transparent;
        }

        @media #{$large-mobile} {
          font-size: 15px;
          padding: 12px 25px;
        }
      }

      .billed-check-box {
        display: flex;
        align-items: center;

        input {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        label {
          cursor: pointer;
          width: 56px;
          height: 24px;
          background-color: var(--color-primary);
          border-radius: 12px;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 4px;
            left: 4px;
            width: 16px;
            height: 16px;
            background-color: var(--color-white);
            border-radius: 50%;
            transition: var(--transition);
          }
        }

        input:checked + label:after {
          left: calc(100% - 4px);
          transform: translateX(-100%);
        }
      }
    }
  }

  .row {
    margin: 0 auto;
    max-width: 1200px;

    > [class*="col"] {
      padding-left: 15px;
      padding-right: 15px;
    }

    &.g-0 {
      margin-left: 0;
      margin-right: 0;

      > [class*="col"] {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .col-lg-4 {
      max-width: 360px;
      width: 32%; // Adjust as needed
      float: left; // Adjust as needed

      @media #{$lg-layout} {
        width: 50%; // Adjust for responsiveness
      }

      @media #{$md-layout} {
        width: 60%;
      }

      @media #{$large-mobile} {
        width: 100%; // Adjust for mobile screens
        margin-right: 0; // Remove margin on smaller screens
      }

      .pricing-table {
        background-color: $white;
        padding: 20px;
        border-radius: 36px;
        position: relative;
        margin-bottom: 30px;
        border: 6px solid transparent;
        border-bottom: none;
        box-shadow: 0 4px 29.5px 0 rgba(0, 0, 0, 0.25);
        height: 791px;
        transition: all 0.5s;

        // @media #{$lg-layout} {
        //   padding: 40px 15px;
        // }
        // @media #{$large-mobile} {
        //   padding: 40px 30px;
        // }
        .pricing-header {
          text-align: center;

          .title {
            color: $secondary-main;
            font-family: "Poppins" sans-serif !important;
            font-weight: 500;
          }

          .popular {
            background-color: $warning-main;
            border-radius: 36px;
            color: $white;
          }

          .price-wrap {
            .amount {
              font-weight: 600;
              font-size: 45px;

              .fs-30 {
                font-size: 30px;
              }
            }
          }

          .pricing-btn {
            background-color: $white;
            width: 90%;
            border: 1px solid $black;
            border-radius: 6px;
            margin-top: 15px;
            font-size: 22px;
            font-weight: 500;
            color: $secondary-main;
          }
        }

        .pricing-body {
          margin-top: 10px;
          max-height: 648px; // Set the max height
          overflow-y: auto; // Enable vertical scrolling

          &::-webkit-scrollbar {
            width: 7px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 100px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $secondary-info;
            border-radius: 100px;
          }

          ul {
            list-style-type: none;
            padding: 5px;

            li {
              padding: 8px 0;
              padding-left: 24px;
              position: relative;

              .checkIcon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        &:hover,
        &.active {
          border: 6px solid $primary-main;
          border-bottom: none;

          .pricing-header {
            .pricing-btn {
              background-color: $primary-main;
              border: 1px solid transparent;
              border-radius: 6px;
              color: $white;
            }
          }

          .pricing-body {
            li {
              color: var(--color-white);
            }
          }
        }
      }

      // Add more styles as needed
    }
  }

  .mb--50 {
    margin-bottom: 50px !important;
  }

  .box {
    padding: 24px;

    .card {
      background-color: $white;
      margin: 0 auto;
      max-width: 1200px;
      text-align: center;
      box-shadow: 0 4px 29.5px 0 rgba(0, 0, 0, 0.25);
      border-radius: 36px;
      transition: all 0.5s;

      p {
        font-size: 34px;
        font-weight: 600;
      }

      div {
        flex-wrap: wrap;

        span {
          width: 30%;
          padding: 10px;
          min-width: fit-content;
          gap: 10px;
          color: $secondary-main;
          font-weight: 200;

          text-align: center;

          /* Center the content within each span */
          .tickIcon {
            display: inline;
            width: 20px;
            height: 20px;
          }

          @media #{$lg-layout} {
            width: 45%; // Adjust for responsiveness
          }
          @media #{$md-layout} {
            width: 100%; // Adjust for responsiveness
          }

          @media #{$large-mobile} {
            width: 100%; // Adjust for mobile screens
            // margin-right: 0; // Remove margin on smaller screens
          }
        }
      }
      &:hover {
        scale: 1.03;
      }
    }
  }

  .bottom-div {
    .innerDiv {
      padding-top: 300px;
      padding-left: 200px;
      position: relative;
      .upperTitle {
        font-size: min(34px, 4vw);
        font-weight: 400;
        color: $white;
      }

      .achieved {
        font-size: min(106px, 7vw);
        font-weight: 600;
        color: $white;
        text-transform: uppercase;
      }

      .center-logo {
        position: absolute;
        width: min(220px, 12vw);
        height: min(220px, 12vw);
        animation: 5s Infinite ease-out floating;
        animation-delay: 0s;
      }
      :nth-child(3) {
        bottom: -46%;
        right: 39%;
      }
      :nth-child(4) {
        bottom: -82%;
        right: 50%;
        animation: 5s Infinite ease-out floating2;
        animation-delay: 0s;
      }
      :nth-child(5) {
        bottom: -105%;
        right: 64%;
      }

      @media #{$lg-layout} {
        padding-top: 110px;

        .achieved {
          font-size: min(106px, 7vw);
        }
        :nth-child(3) {
          bottom: -67%;
          right: 39%;
        }
        :nth-child(4) {
          bottom: -92%;
          right: 50%;
        }
        :nth-child(5) {
          bottom: -101%;
          right: 62%;
        }
      }

      @media #{$md-layout} {
        padding-top: 75px;
        padding-left: 90px;

        .achieved {
          font-size: min(106px, 7vw);
        }
        :nth-child(3) {
          bottom: -67%;
          right: 39%;
        }
        :nth-child(4) {
          bottom: -92%;
          right: 50%;
        }
        :nth-child(5) {
          bottom: -101%;
          right: 62%;
        }
      }

      @media #{$large-mobile} {
        padding-top: 55px;
        padding-left: 55px;

        .achieved {
          font-size: min(106px, 7vw);
        }
        .center-logo {
          width: min(220px, 18vw);
          height: min(220px, 18vw);
        }
        :nth-child(3) {
          bottom: -85%;
          left: 45%;
        }
        :nth-child(4) {
          bottom: -115%;
          left: 28%;
        }
        :nth-child(5) {
          bottom: -115%;
          left: 10%;
        }
      }
    }

    // div {
    .ring-div {
      div {
        position: relative;

        .ring {
          width: min(760px, 42vw);
          height: min(760px, 42vw);
        }

        .brandLogos {
          object-fit: contain;
          position: absolute;
          border-radius: 100%;
          transition: all 0.5s;
        }

        .brandLogos.active {
          scale: 1.3;
          padding: 0;
        }

        :nth-child(2) {
          width: 17.10526%;
          height: 17.10526%;
          top: 8.55263%;
          right: 14.73684%;
        }

        :nth-child(3) {
          width: 15.78947%;
          height: 15.78947%;
          top: 5.89474%;
          right: 31.83333%;
        }

        :nth-child(4) {
          width: 19.73684%;
          height: 19.73684%;
          top: 10.52632%;
          right: 46.63158%;
        }

        :nth-child(5) {
          width: 21.71053%;
          height: 21.71053%;
          top: 27.63158%;
          right: 59.21053%;
        }

        :nth-child(6) {
          width: 21.71053%;
          height: 21.71053%;
          bottom: 27.63158%;
          right: 59.21053%;
        }

        :nth-child(7) {
          width: 19.73684%;
          height: 19.73684%;
          bottom: 10.52632%;
          right: 46.63158%;
        }

        :nth-child(8) {
          width: 15.78947%;
          height: 15.78947%;
          bottom: 7.89474%;
          right: 31.83333%;
        }

        :nth-child(9) {
          width: 15.78947%;
          height: 15.78947%;
          bottom: 8.55263%;
          right: 14.73684%;
        }

        :nth-child(10) {
          width: min(18em, 16vw);
          height: min(18em, 16vw);
          top: 50%;
          right: 0%;
          transform: translate(-50%, -50%);
        }
      }
    }

    div {
      .para {
        margin: 40px;
        font-size: min(34px, 4vw);
        max-width: 700px;
        color: $black;
        text-align: center;
        font-weight: 600;

        span {
          color: $primary-main;
        }
      }
    }
  }
  @keyframes floating {
    0% {
      opacity: 1;
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @keyframes floating2 {
    0% {
      opacity: 1;
      transform: translateY(-15px);
    }
    50% {
      transform: translateY(0px);
    }
    100% {
      opacity: 1;
      transform: translateY(-15px);
    }
  }
}
