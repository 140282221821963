.form {
    max-width: 70%;
    margin: 20px auto;

    .form-info {
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 20px;

        .heading {
            color: $primary-main;
        }


    }

    .detail1 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(15, auto);
        grid-column-gap: 50px;
        grid-row-gap: 50px;
        align-items: start;
    }

    .div1 {
        grid-area: 2 / 1 / 3 / 3;

        .error {
            padding: 0 20px;
            color: red;
        }
    }

    .div2 {
        grid-area: 2 / 3 / 3 / 5;

        .error {
            padding: 0 20px;
            color: red;
        }

    }

    .div3 {
        grid-area: 3 / 1 / 4 / 3;
    }

    .div4 {
        grid-area: 3 / 3 / 4 / 5;

        .error {
            padding: 0 20px;
            color: red;
        }

    }

    .div5 {
        grid-area: 4 / 1 / 5 / 3;

        .error {
            padding: 0 20px;
            color: red;
        }

    }

    .div6 {
        grid-area: 4 / 3 / 5 / 5;
    }

    .div7 {
        grid-area: 5 / 1 / 6 / 5;
    }

    .div8 {
        grid-area: 6 / 1 / 7 / 5;
    }

    .div9 {
        grid-area: 7 / 1 / 8 / 5;
    }

    .div10 {
        grid-area: 8 / 1 / 9 / 5;
    }

    .div11 {
        grid-area: 9 / 1 / 10 / 5;
    }

    .div12 {
        grid-area: 10 / 1 / 11 / 5;
    }

    .div13 {
        grid-area: 11 / 1 / 12 / 5;
    }

    .div14 {
        grid-area: 12 / 1 / 13 / 5;
    }

    .div15 {
        grid-area: 13 / 1 / 14 / 5;
    }

    .div16 {
        grid-area: 14 / 1 / 15 / 5;
    }

    .div17 {
        grid-area: 15 / 1 / 16 / 5;
    }


    @media (max-width: 1024px) {
        .detail1 {
            grid-template-columns: 1fr;
        }

        .div1,
        .div2,
        .div3,
        .div4,
        .div5,
        .div6,
        .div7,
        .div8,
        .div9,
        .div10,
        .div11,
        .div12,
        .div13,
        .div14,
        .div15,
        .div16,
        .div17 {
            grid-area: auto;
        }
    }

    @media (max-width: 480px) {
        .detail1 {
            grid-column-gap: 20px;
            grid-row-gap: 20px;
        }
    }


    .detail2 {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .logo-types {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;

            .logo-head {
                font-size: 20px;
                color: $primary-main;

            }

            .logos {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;

                .logo-box {
                    position: relative;
                    // border: 2px solid black;
                    height: 150px;
                    width: 150px;
                    // overflow: hidden;

                    .checkbox-container {
                        display: inline-block;
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        padding: 0;
                        width: 25px;
                        /* Adjust the width to the size of the circle */
                        height: 25px;
                        /* Adjust the height to the size of the circle */
                        cursor: pointer;
                        user-select: none;
                        /* Prevent user from selecting the text */
                        z-index: 100;
                    }

                    /* Hide the browser's default checkbox */
                    .logo-check {
                        opacity: 0;
                        position: absolute;
                        cursor: pointer;
                        height: 100%;
                        width: 100%;
                        margin: 0;
                        z-index: 1;
                        /* Ensures the input is above the custom checkmark for click events */
                    }

                    /* Create a custom circular checkbox */
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-color: gray;
                        /* Default background for unchecked state */
                        border-radius: 50%;
                        /* Makes it circular */
                        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
                        /* Optional: adds shadow for better visibility */
                        transition: background-color 0.3s;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        /* Default color of the checkmark for unchecked state */
                    }

                    /* Pseudo-element to represent the checkmark symbol */
                    .checkmark::after {
                        content: '\2713';
                        /* Unicode for checkmark */
                        display: block;
                    }

                    /* When the checkbox is checked, change the background and checkmark color */
                    .logo-check:checked+.checkmark {
                        background-color: $primary-main;
                        /* Dark background */
                        box-shadow: 0 0 2px #444;
                        /* Darken the shadow a bit */
                        color: white;
                        /* Change checkmark color to red */
                    }





                    .logo-img {
                        object-fit: cover;
                    }

                    .text-box {
                        width: 100%;
                        position: absolute;
                        bottom: 5px;

                    }

                    .logo-text {
                        text-align: center;
                        color: white;
                        text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
                    }
                }
            }
        }
    }


    .detail3 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;
        /* Adjusted for better spacing on smaller screens */
        align-items: start;
    }

    .detail3-info {
        color: $primary-main;
        width: 100%;
        margin: 60px 0 0 0;
        padding: 0px 20px;
    }

    .detail3-div1,
    .detail3-div2,
    .detail3-div3,
    .detail3-div4,
    .detail3-div5,
    .detail3-div6,
    .detail3-div7,
    .detail3-div8 {
        grid-column: 1 / -1;
        /* Make each div span full width on smaller screens */
    }

    .detail3-div8 {
        display: flex;
        flex-direction: column;
        /* Stack children vertically on small screens */
        gap: 20px;
    }

    .file-upload {
        width: 100%;
        padding: 10px;
        border: 2px solid transparent;
        border-bottom: 2px solid black;
        font-size: 17px;
        color: #858585;

    }

    .file-upload::file-selector-button {
        padding: 5px;
        font-size: 15px;
    }

    .file-info {
        color: #858585;
        padding: 20px;
        font-size: 15px;
    }

    @media (min-width: 768px) {
        .detail3 {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }

        .detail3-div1,
        .detail3-div3,
        .detail3-div5 {
            grid-column: 1 / 2;
        }

        .detail3-div2,
        .detail3-div4,
        .detail3-div6 {
            grid-column: 2 / 3;
        }

        .detail3-div7,
        .detail3-div8 {
            grid-column: 1 / 3;
        }

        .detail3-div8 {
            flex-direction: row;
            /* Use row layout for larger screens */
        }
    }

    /* Large screens (Desktops) */
    @media (min-width: 1024px) {
        .detail3 {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(15, auto);
            grid-column-gap: 50px;
            grid-row-gap: 50px;
        }

        .detail3-div1 {
            grid-area: 2 / 1 / 3 / 3;
        }

        .detail3-div2 {
            grid-area: 2 / 3 / 3 / 5;
        }

        .detail3-div3 {
            grid-area: 3 / 1 / 4 / 3;
        }

        .detail3-div4 {
            grid-area: 3 / 3 / 4 / 5;
        }

        .detail3-div5 {
            grid-area: 4 / 1 / 5 / 3;
        }

        .detail3-div6 {
            grid-area: 4 / 3 / 5 / 5;
        }

        .detail3-div7 {
            grid-area: 6 / 1 / 7 / 5;
        }

        .detail3-div8 {
            grid-area: 7 / 1 / 8 / 5;
            flex-direction: row;
            /* Restore horizontal layout for larger screens */
        }
    }

    .submit-button,
    .cont-button {
        border-radius: 0;
        padding: 15px 40px;
        color: hsl(0, 0%, 100%);
    }

    .submit-button {
        background-color: $primary-main;
        color: white;

        transition: all 0.75s linear;
    }

    .cont-button {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transition: all 0.75s all;
    }

    .cont-button:hover {
        box-shadow: #9A2DB1 0px 3px 8px;
    }

    .submit-button:hover {
        background-color: black;
        color: white;
    }

    .submit-button:disabled {
        background-color: #cccccc;
        /* Disabled button background color */
        color: #666666;
        /* Disabled button text color */
        cursor: not-allowed;
        /* Disabled cursor */
    }

}