.footer {
  .l-h {
    line-height: 2rem;
  }
  background: rgb(0, 90, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 90, 255, 1) 59%,
    rgba(0, 191, 255, 1) 95%
  );
  .inner-div {
    overflow: hidden;
    color: $white;
    padding: 25px;
    margin: 0 auto;
    max-width: 1400px;

    .topDiv {
      flex-wrap: wrap;
      padding-bottom: min(50px, 6vw);
      :nth-child(1) {
        img {
          width: 130px;
          height: 130px;
          padding: 15px 0;
        }
        .input-container {
          //   border: 1px solid red;
          width: fit-content;
          position: relative;
          input {
            color: $purple;
            padding: 15px 110px 15px 30px;
            border-radius: 35px;
            border: none;
            outline: none;

            &::placeholder {
              color: $purple;
            }
          }
          button {
            position: absolute;
            width: 100px;
            right: 0;
            bottom: 0;
            border-radius: 35px;
            border: 1px solid $purple;
            color: $purple;
          }
        }
      }

      :nth-child(3) {
        .logos-div {
          display: flex;
          gap: 10px;
          .logo-span {
            z-index: 10;
            border-radius: 10px;
            background-color: $white;
            overflow: hidden;
            .socialIcons {
              background-color: $white;
              padding: 0;
              object-fit: contain;
              width: 60px;
              height: 45px;
            }
          }
        }
      }
    }
    .bottom-div {
      padding: 20px 0;
      position: relative;

      .abs-logo {
        width: 420px;
        height: 420px;
        position: absolute;
        right: 0%;
        bottom: -290%;
        opacity: 20%;
      }

      .tern {
        z-index: 10;
      }
    }
  }

  @media #{$lg-layout} {
    .inner-div {
      .bottom-div {
        .abs-logo {
          width: 320px;
          height: 320px;
          bottom: -150%;
        }
      }
    }
  }
  @media #{$md-layout} {
    .inner-div {
      .topDiv {
        flex-direction: column;
      }
      .bottom-div {
        flex-direction: column;
        p {
          padding: 10px 0;
        }
        .abs-logo {
          width: 320px;
          height: 320px;
          bottom: -100%;
        }
      }
    }
  }
}
