.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: 'red' !important;
    height: 100vh;
    width: 100%;


    @media #{$small-mobile} {
        video {
            width: 90%;
        }
    }
}