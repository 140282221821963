.header {
  background-color: $secondary-main;
  color: $white;

  ::-webkit-scrollbar {
    display: none;
  }


  .head-logo {
    img {
      width: 270px;
      height: 64px;

      @media only screen and (min-width: 767px) and (max-width: 991px) {
        width: 190px;
      }

      @media #{$small-mobile} {
        width: 250px;
      }

    }

  }

  &.sticky {
    background-color: rgba(0, 0, 0, 0.7) !important;
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    padding: 16px 0px !important;
  }

  .navMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      list-style: none;
      padding-left: 0;

      li {
        padding: 0 20px;
        cursor: pointer;
        color: #fff;

        &:hover,
        &.active {
          color: $primary-info;
        }


        @media #{$lg-layout} {
          padding: 0 12px;
        }

        @media #{$md-layout} {
          padding: 0 4px;
        }

        .dropdownMenu {
          ul {
            background-color: $secondary-main;
            max-width: 300px;
            height: auto;
            position: fixed;
            margin-top: 15px;
            margin-left: -5%;
            border-radius: 18px;
            box-shadow: 0px 7px 31.3px 0px rgba(0, 0, 0, 0.25);
            border: 2px solid $primary-info;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px !important;
            z-index: 10;

            li {
              word-break: break-all;

              .btn-Link {

                &:hover,
                &.active {
                  color: $primary-info;
                }
              }
            }

            .menuIco {
              width: 13px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  .hide-header {
    transition: all 0.5s;
  }

  .phoneIco {
    width: 18px;
    height: 18px;

    @media #{$lg-layout} {
      width: 14px;
      height: 14px;
    }

  }

  .hambugerIcon {
    width: 48px;
    height: 48px;
  }

  .mobile-menu,
  .showX {
    display: none;
  }

  @media (max-width: 768px) {
    &.sticky {
      padding: 2rem;
    }

    .mobile-menu,
    .showX {
      padding-right: 30px;
      transition: all 0.5s;
      display: block;
      cursor: pointer;

      @media #{$small-mobile} {
        padding-right: 0px;

      }
    }

    .mobileMenu {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(0%);
      z-index: 9999999;
      background-color: $secondary-main;
      height: calc(100vh - 0px);
      overflow: hidden;
      width: 100%;
      font-size: 18px;

      overflow-y: scroll;

      ::-webkit-scrollbar {
        display: none;
      }

      li {
        padding: 10px 0;
        margin-bottom: 10px;
      }
    }

    .navMenu {
      transform: translateX(-100%);

      ul {
        display: none;
      }

      .hide-btn {
        display: none;
      }

      transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .dropdownMenu {
      ul {
        background-color: $secondary-main;
        max-width: 300px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px !important;

        li {
          word-break: break-all;


          &:hover,
          &.active {
            color: $primary-info;
          }

        }




        .menuIco {
          width: 13px;
          height: 14px;
        }
      }
    }

    .btnContact {
      // position: absolute;
      // bottom: 5%;
      // left: 5%;
      width: fit-content;
      margin: 15px 10px 15px 30px;
    }



    .transtionAnima {
      transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }

  .primary-button {
    border: 2px solid $tertiary-main;
    padding: 4px 15px;

    @media only screen and (min-width: 767px) and (max-width: 991px) {
      font-size: 7px;
      padding: 4px 12px;

      .phoneIco {
        width: 12px;
        height: 12px;
      }
    }
  }
}