.logo-box {
    position: relative;
    // border: 2px solid black;
    height: 45px;
    width: 45px;
    // overflow: hidden;

    .checkbox-container {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 0;
        width: 25px;
        /* Adjust the width to the size of the circle */
        height: 25px;
        /* Adjust the height to the size of the circle */
        cursor: pointer;
        user-select: none;
        /* Prevent user from selecting the text */
        z-index: 100;
    }

    /* Hide the browser's default checkbox */
    .logo-check {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        height: 100%;
        width: 100%;
        margin: 0;
        z-index: 1;
        /* Ensures the input is above the custom checkmark for click events */
    }

    /* Create a custom circular checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: gray;
        /* Default background for unchecked state */
        border-radius: 50%;
        /* Makes it circular */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        /* Optional: adds shadow for better visibility */
        transition: background-color 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        /* Default color of the checkmark for unchecked state */
    }


    .cross-mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: gray;
        /* Default background for unchecked state */
        border-radius: 50%;
        /* Makes it circular */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        /* Optional: adds shadow for better visibility */
        transition: background-color 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        /* Default color for unchecked state */
    }

    .checkmark::after {
        content: '\2713';
        /* Unicode for checkmark */
        display: block;
    }

    /* When the checkbox is checked, change the background and checkmark color */
    .logo-check:checked+.checkmark {
        background-color: $primary-main;
        /* Dark background */
        box-shadow: 0 0 2px #444;
        /* Darken the shadow a bit */
        color: white;
        /* Change checkmark color to $primary-main */
    }

    /* Pseudo-element to represent the cross symbol */
    .cross-mark::after {
        content: '\2717';
        /* Unicode for cross mark */
        display: block;
    }

    /* When the checkbox is checked, change the background and cross color */
    .logo-check:checked+.cross-mark {
        background-color: $primary-main;
        /* Dark background */
        box-shadow: 0 0 2px #444;
        /* Darken the shadow a bit */
        color: white;
        /* Change cross color to white */
    }


    /* Pseudo-element to represent the checkmark symbol */






    .logo-img {
        object-fit: cover;
    }

    .text-box {
        width: 100%;
        position: absolute;
        bottom: 5px;

    }

    .logo-text {
        text-align: center;
        color: white;
        text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
    }
}